import React from "react"
import WorkExperienceDivider from "../../../static/images/projects_divider.svg"
import WorkExperienceHeader from "../../../static/images/workexp_header.svg"
import EbayLogo from "../../../static/images/ebay_logo.jpg"
import PhysioLogo from "../../../static/images/physio_logo.png"
import StrykerLogo from "../../../static/images/stryker_logo.jpg"
import UMLogo from "../../../static/images/um_logo.png"

import "./styles/work-exp.scss"

const WorkExperience = () => (
  <>
    <div class="project-divider-container">
      <img class="project-divider" src={WorkExperienceDivider} />
    </div>

    <div class="workexp_container">
      <div id="work" class="workexp_body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-s-12">
            <img class="workexp_header" src={WorkExperienceHeader} />
          </div>
        </div>
        <div class="row workexp_row">
          <div class="col-xl-3 col-lg-4 col-md-6 col-s-6">
            <div class="job_date">AUG 2019 - PRESENT</div>
            <div class="job_card_container">
              <div class="job_card">
                <div class="job_card_img_container">
                  <div class="company_logo_container">
                    <img class="company_logo" src={EbayLogo} />
                  </div>
                </div>
                <div class="job_card_description_container">
                  <div class="btn_container">
                    <div class="x"> </div>
                  </div>
                  <div class="job_card_position">
                    <span> Software Engineer </span>
                  </div>
                  <div class="job_card_department_location"></div>
                  <div class="job_card_detailed_description">
                    <span>
                      {" "}
                      Developing mobile and desktop nodeJS/React web
                      applications within eBay’s Checkout team. We work across
                      domains to create accessible, scalable interfaces for eBay
                      Payments.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
            <div class="job_date">MAY 2018 - AUG 2018</div>
            <div class="job_card_container">
              <div class="job_card">
                <div
                  class="job_card_img_container"
                  id="physio_card_img_container"
                >
                  <div class="company_logo_container">
                    <img
                      class="company_logo"
                      id="physio_logo"
                      src={PhysioLogo}
                    />
                  </div>
                </div>
                <div
                  class="job_card_description_container"
                  id="physio_card_description_container"
                >
                  <div class="btn_container">
                    <div class="x"> </div>
                  </div>
                  <div class="job_card_position">
                    <span> Software Intern </span>
                  </div>
                  <div
                    id="physio_headers"
                    class="job_card_department_location physio_subheaders_color"
                  ></div>
                  <div class="job_card_detailed_description">
                    <span>
                      {" "}
                      I built a C# library and GUI for an AED automated testing
                      suite used by field specialists. This library utilized
                      serial port programming and facilitated asynchronous
                      communication.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-s-12 work_exp_col">
            <div class="job_date">MAY 2017 - AUG 2017</div>
            <div class="job_card_container">
              <div class="job_card">
                <div
                  class="job_card_img_container"
                  id="stryker_card_img_container"
                >
                  <div class="company_logo_container">
                    <img
                      class="company_logo"
                      id="stryker_logo"
                      src={StrykerLogo}
                    />
                  </div>
                </div>
                <div
                  class="job_card_description_container"
                  id="stryker_card_description_container"
                >
                  <div class="btn_container">
                    <div class="x"> </div>
                  </div>
                  <div class="job_card_position">
                    <span> Software Intern </span>
                  </div>
                  <div
                    id="stryker_headers"
                    class="job_card_department_location"
                  ></div>
                  <div class="job_card_detailed_description">
                    <span>
                      {" "}
                      I engineered a full stack Python/LabVIEW Machine Vision
                      Actuation Platform and designed a GUI for full systems
                      integration testing by Stryker Test Lab Technicians.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-12 col-md-6 col-s-6 work_exp_col">
            <div class="job_date">JAN 2015 - SEP 2017</div>
            <div class="job_card_container">
              <div class="job_card">
                <div class="job_card_img_container" id="um_card_img_container">
                  <div class="company_logo_container">
                    <img class="company_logo" id="um_logo" src={UMLogo} />
                  </div>
                </div>
                <div
                  class="job_card_description_container"
                  id="um_card_description_container"
                >
                  <div class="btn_container">
                    <div class="x"> </div>
                  </div>
                  <div class="job_card_position">
                    <span> Assistive Tech. Intern </span>
                  </div>
                  <div
                    id="um_headers"
                    class="job_card_department_location"
                  ></div>
                  <div class="job_card_detailed_description">
                    <span>
                      {" "}
                      Led different projects within Mott Children's Hospital and
                      UM Medical Center's IT Department, including an initiative
                      to install 500 xboxes within Mott Children's Hospital.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default WorkExperience
