import React from "react"
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt
} from "@fortawesome/free-solid-svg-icons"
import Blobs from "../../components/blob/blob"
import BlueBlob from '../../../static/contactme-blobs/blue-home.svg'
import GreenBlob from '../../../static/contactme-blobs/green-home.svg'
import RedBlob from '../../../static/contactme-blobs/red-home.svg'
import OrangeBlob from '../../../static/contactme-blobs/orange-home.svg'
import ContactMeHeader from "../../../static/images/contactme-header.svg"
import ContactMeDivider from "../../../static/images/contactme-divider.svg"

import "./styles/contact-me.scss"

const blobIds = [`blob5`, `blob6`, `blob7`, `blob8`]

const ContactMe = () => (
  <div id="contactme" class="contactme_bg">
    <div class="contactme-divider-container">
      <img class="contactme-divider" src={ContactMeDivider} />
    </div>
  <div class="contactme_header_container">
    <img class="contactme_header" src={ContactMeHeader} />    
    <div class="contactme_links">
      <a href="https://www.linkedin.com/in/james-bueche-6687a711b/" class="links__linkedin">
        <div class="linkedin">
        <FontAwesomeIcon icon={faLinkedin} />
        </div>
      </a>
      <a href="mailto:bueche.james@gmail.com" class="links__linkedin">
        <div class="linkedin">
          <FontAwesomeIcon icon={faAt} />
        </div>
      </a>
    </div>
  </div>
  <Blobs blobIds={blobIds}>
    <BlueBlob />
    <GreenBlob />
    <RedBlob />
    <OrangeBlob />
  </Blobs>
</div> 
)

export default ContactMe
