import React from "react"
import ProjectHeader from "../../../static/images/project_header.svg"
import InstagramLogo from "../../../static/images/instalog.png"
import PythonLogo from "../../../static/images/python_logo.png"
import FlaskLogo from "../../../static/images/flask_logo.png"
import ReactLogo from "../../../static/images/react_logo.png"
import BootstrapLogo from "../../../static/images/bootstrap_logo.png"
import RyderLogo from "../../../static/images/ryder_logo.png"
import AlexaLogo from "../../../static/images/alexa_logo.png"
import NodeLogo from "../../../static/images/node_logo.png"
import AmassLogo from "../../../static/images/amass.png"
import ChatLogo from "../../../static/images/chat_logo.png"

import "./styles/projects.scss"

const Projects = () => (
  <>
    <div id="projects" class="project_container">
      <div class="projects-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-s-12">
          <img
            class="projects_header mt-11 aboutme_header"
            src={ProjectHeader}
          />
        </div>
        </div>
        <div class="row workexp_row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div
                class="job_card_img_container"
                id="instagram_card_img_container"
              >
                <div class="company_logo_container">
                  <img
                    class="company_logo"
                    id="instagram_logo"
                    src={InstagramLogo}
                  />
                </div>
              </div>
              <div
                class="job_card_description_container"
                id="instagram_card_description_container"
              >
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> Instagram Clone </span>
                </div>
                <div class="job_card_detailed_description">
                  <span>
                    {" "}
                    Web application and Rest API developed to mimic the
                    Instagram website and its functionality
                  </span>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="python_logo" src={PythonLogo} />
                  <img class="tool_logo" id="flask_logo" src={FlaskLogo} />
                  <img class="tool_logo" id="react_logo" src={ReactLogo} />
                  <img
                    class="tool_logo"
                    id="bootstrap_logo"
                    src={BootstrapLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="ryder_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" id="ryder_logo" src={RyderLogo} />
                </div>
              </div>
              <div
                class="job_card_description_container"
                id="ryder_card_description_container"
              >
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> Ryder </span>
                </div>
                <div class="job_card_detailed_description">
                  <span>
                    {" "}
                    Alexa skill for users to find and book the quickest ride
                    nearby.
                  </span>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="alexa_logo" src={AlexaLogo} />
                  <img class="tool_logo" id="node_logo" src={NodeLogo} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="amass_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" id="amass_logo" src={AmassLogo} />
                </div>
              </div>

              <div
                class="job_card_description_container"
                id="amass_card_description_container"
              >
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> amass </span>
                </div>
                <div class="job_card_detailed_description">
                  <span>
                    {" "}
                    React web application engineered for Ann Arbor residents to
                    post and find local events.
                  </span>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="react_logo" src={ReactLogo} />
                  <img
                    class="tool_logo"
                    id="bootstrap_logo"
                    src={BootstrapLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-s-6 work_exp_col">
          <div class="project_card_container">
            <div class="job_card">
              <div class="job_card_img_container" id="chat_card_img_container">
                <div class="company_logo_container">
                  <img class="company_logo" id="chat_logo" src={ChatLogo} />
                </div>
              </div>

              <div
                class="job_card_description_container"
                id="chat_card_description_container"
              >
                <div class="btn_container">
                  <div class="x"> </div>
                </div>
                <div class="job_card_position">
                  <span> MChat </span>
                </div>
                <div class="job_card_detailed_description">
                  <span>
                    {" "}
                    NodeJS web chat application for Mott Children's Hospital
                    built with Express, SQLite, and JQuery
                  </span>
                </div>
                <div class="job_card_tools_container">
                  <img class="tool_logo" id="node_logo" src={NodeLogo} />
                  <img
                    class="tool_logo"
                    id="bootstrap_logo"
                    src={BootstrapLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
</div>
  </>
)

export default Projects
