import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faBriefcase,
  faWrench,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import "./styles/nav.scss"

const Nav = () => (
  <nav class="menu">
    <input
      type="checkbox"
      href="#"
      class="menu-open"
      name="menu-open"
      id="menu-open"
    />
    <label class="menu-open-button" for="menu-open">
      <span class="hamburger hamburger-1"></span>
      <span class="hamburger hamburger-2"></span>
      <span class="hamburger hamburger-3"></span>
    </label>
    <a href="#aboutme" class="menu-item">
      {" "}
      <FontAwesomeIcon icon={faUser} />{" "}
    </a>
    <a href="#work" class="menu-item">
      {" "}
      <FontAwesomeIcon icon={faBriefcase} />{" "}
    </a>
    <a href="#projects" class="menu-item">
      {" "}
      <FontAwesomeIcon icon={faWrench} />{" "}
    </a>
    <a href="#contactme" class="menu-item">
      {" "}
      <FontAwesomeIcon icon={faEnvelope} />{" "}
    </a>
  </nav>
)

export default Nav
