import React from "react"
import DuneBlobPhoto from "../../../static/images/dune-blobpng.png"
import OutdoorBlobPhoto from "../../../static/images/outdoor-blobpng.png"
import ProfilePhoto from "../../../static/images/photo_shoot2.png"
import AboutMeHeader from "../../../static/images/header-about.svg"

import "./styles/about-me.scss"

const AboutMe = () => (
  <div id="aboutme" class="aboutme_bg">
    <div class="aboutme_body workexp_body">
      <img class="dune-blob" src={DuneBlobPhoto} />
      <img class="outdoor-blob" src={OutdoorBlobPhoto} />
      <div class="row">
        <div class="pl-0 col-lg-12 col-md-12 col-s-12">
          <img class="aboutme_header" src={AboutMeHeader} />
        </div>
      </div>
      <div class="aboutme_container">
        <div class="row aboutme-row">
          <div class="aboutme-left-col col-lg-6 col-md-12 col-sm-12">
            <div class="aboutme-left-col-bg">
              <div class="aboutme-emoji-intro">Hey thanks for stopping by!</div>
              <div class="aboutme-body">
                I'm a <span class="bolder">design-driven</span> frontend developer
                from Michigan currently living in the bay area.
              </div>
              <div class="aboutme-body">
                I believe <span class="bolder">good design</span> fuels{" "}
                <span class="bolder">productive development</span>. I love
                designing and creating web applications that are simple and
                joyful.
              </div>
              <div class="aboutme-body">
                These days I write nodeJS/react web applications within eBay's
                Payments org that are used by millions of customers every day.
              </div>
              <div class="aboutme-body">
                Take a look around and feel free to{" "}
                <a href="#contactme">contact me</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="photo_shoot_container">
              <div class="gradient_block"></div>
              <img class="professional_photo" src={ProfilePhoto} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AboutMe
