import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import {
  isMobile
} from "react-device-detect";
import Home from "../sections/home/home"
import Aboutme from "../sections/about-me/about-me"
import WorkExperience from "../sections/work-exp/work-exp"
import Projects from "../sections/projects/projects"
import ContactMe from "../sections/contact-me/contact-me"

import "./styles/index.scss"

const IndexPage = () => {
  const [isDeviceMobile, setIsDeviceMobile] = useState()
  useEffect(() => {
    if(isMobile) {
      setIsDeviceMobile(true)
    } else {
      setIsDeviceMobile(false)
    }
  }, [])
  return(
    <div className={`app ${isDeviceMobile ? 'mobile' : 'desktop'}`}>
    <Home />
    <Aboutme />
    <WorkExperience />
    <Projects />
    <ContactMe />
    <Helmet>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossorigin="anonymous"
      />
      <script
        src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
        integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
        crossorigin="anonymous"
      ></script>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
        integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
        crossorigin="anonymous"
      ></script>
      <script
        src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
        integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
        crossorigin="anonymous"
      ></script>
    </Helmet>
  </div>)

  }

export default IndexPage
